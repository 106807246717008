import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment, { Moment } from 'moment';
import type { RootState } from '../store';
import { MomentRange } from '../../utils';
import { Option } from 'react-multi-select-component';

enum RelativeDateType {
  TODAY = 0,
  YESTERDAY = 1,
  THIS_WEEK = 2,
  LAST_WEEK = 3,
  THIS_MONTH = 4,
  LAST_MONTH = 5,
  THIS_YEAR = 6,
  LAST_YEAR = 7,
}

export interface FilterDateRange {
  type: 'absolute' | 'relative';
  relativeType?: RelativeDateType;
  start?: Moment;
  end?: Moment;
}

export interface Filter {
  field: string;
  values: string[] | number[];
}

export interface FilterOptions {
  display: string;
  field: string;
  values: Option[];
}

// Define a type for the slice state
interface FilterState {
  showBar: boolean;
  dateRange: FilterDateRange;
  compareRange: FilterDateRange;
  available: FilterOptions[];
  selected: FilterOptions[];
  active: FilterOptions[];
  mainFilters: string[];
  compare: boolean;
}

// Define the initial state using that type
const months = process.env.REACT_APP_DATE_RANGE_DEFAULT_MONTH && parseInt(process.env.REACT_APP_DATE_RANGE_DEFAULT_MONTH);
const initialState: FilterState = {
  showBar: false,
  dateRange: {
    type: 'absolute',
    start: moment('04/01/2023').subtract(months ? months : 0, 'month').startOf('month').startOf('day'),
    end: moment('04/12/2023').endOf('day'),
  },
  compareRange: {
    type: 'absolute',
    start: moment('04/01/2023').subtract(months ? (months * 2)+1 : 1, 'month').startOf('month').startOf('day'),
    end: moment('04/12/2023').subtract(months ? months + 1 : 1, 'month').endOf('month').endOf('day'),
  },
  available: [],
  selected: [],
  active: [],
  mainFilters: [],
  compare: false
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    showFilterBar: (state) => {
      //state.selected = state.active.slice();
      state.showBar = true;
    },
    hideFilterBar: (state) => {
      state.showBar = false;
    },
    setFilters: (state, action: PayloadAction<FilterOptions>) => {
      const currentValueIndex = state.selected.findIndex(f => f.field === action.payload.field);
      if (currentValueIndex > -1) {
        if (action.payload.values.length > 0) {
          state.selected.splice(currentValueIndex, 1, action.payload);
        } else {
          state.selected.splice(currentValueIndex, 1);
        }
      } else {
        state.selected.push(action.payload);
      }
    },
    setFiltersActive: (state) => {
      state.showBar = false;
      state.active = state.selected.slice();
    },
    setAvailableFilters: (state, action: PayloadAction<FilterOptions[]>) => {
      state.available = action.payload;
    },
    setMainFilters: (state, action: PayloadAction<string[]>) => {
      state.mainFilters = action.payload.slice(0, 5);
    },
    clearFilters: (state) => {
      state.selected = [];
      state.active = [];
    },
    setDateRange: (state: FilterState, action: PayloadAction<MomentRange>) => {
      state.dateRange = {
        ...state.dateRange,
        ...action.payload,
      }
    },
    setCompareRange: (state: FilterState, action: PayloadAction<MomentRange>) => {
      state.compareRange = {
        ...state.compareRange,
        ...action.payload,
      }
    },
    setCompare: (state: FilterState , action: PayloadAction<boolean>) => {
      state.compare = action.payload;
    },
  },
})

export const {
  clearFilters,
  hideFilterBar,
  setFilters,
  setDateRange,
  setCompareRange,
  setFiltersActive,
  setAvailableFilters,
  setMainFilters,
  showFilterBar,
  setCompare
} = filtersSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectFiltersSlice = (state: RootState) => state.filters;
export const selectDateRange = (state: RootState) => state.filters.dateRange;
export const selectCompareRange = (state: RootState) => state.filters.compareRange;
export const selectCompare = (state: RootState) => state.filters.compare;
export const selectFilters = (state: RootState) => state.filters.active;
export const selectAvailableFilters = (state: RootState) => state.filters.available;
export const selectMainFilters = (state: RootState) =>
  state.filters.available.filter(av => state.filters.mainFilters.includes(av.field));
export const selectAvailableFilterByDisplay = createSelector(
  selectAvailableFilters,
  (_: RootState, display: string) => display,
  (availableFilters, display) => availableFilters.find(sf => sf.display === display)
)
export const getAutocompleteSuggestionsByFilterDisplay = createSelector(
  selectAvailableFilterByDisplay,
  (filter) => filter ? filter.values.map(val => ({ id: val.value, name: val.label })) : []
)

export default filtersSlice.reducer;